<template>
  <!-- 个人中心 -->
  <div style="background:#eee">
    <van-nav-bar title="个人中心" left-text="返回" left-arrow @click-left="goback" />
    <div style="height:7.4rem;display:block;background:#1989fa;color: #fff;">
      <div style="display:flex;padding:0.8rem 1.2rem;align-items:center;">
        <van-image round width="6rem" height="6rem" :src="require('./touxiang.png')" />
        <div style="font-size:1.2rem;margin-left:1.2rem;text-align: left">
          <span>{{ userName }}</span><br>
          <div v-show="isShowReceivingOrder()">
            <van-tag type="success" v-show="receivingOrder == 0">接单中</van-tag>
            <van-tag type="danger" v-show="receivingOrder == 1">暂不接单</van-tag>
          </div>
          <span style="font-size:0.5rem">
            当前角色:<span style="color: red">{{ roleName }}</span>
          </span>
        </div>
      </div>
    </div>
    <van-cell-group style="margin-top:0.8rem;" v-show="authorization('/order-data-manage')">
      <van-cell title="全部订单" style="padding:0.6rem;text-align:left" is-link value="查看全部订单" to="/OrderList" />
      <van-row>
        <van-col span="8" style="padding:1rem 0;" v-for="(itme, index) in orderList" :key="index"
          @click="goOrderList(itme.type)">
          <van-icon v-show="itme.num > 0" style="font-size:1.6rem;padding-bottom:0.2rem;" :name="itme.icon"
            :color="itme.color" :badge="itme.num" />
          <van-icon v-show="itme.num <= 0" style="font-size:1.6rem;padding-bottom:0.2rem;" :name="itme.icon"
            :color="itme.color" />
          <br>
          <span style="font-size:0.8rem">{{ itme.title }}</span>
        </van-col>
      </van-row>
    </van-cell-group>
    <van-cell-group style="margin-top:0.8rem;">
      <van-cell title="管理中心" style="padding:0.6rem;text-align:left" />
      <van-row>
        <van-col span="8" style="padding:1rem 0;" v-for="(itme, index) in mamageList" v-show="!itme.isHidden" :key="index"
          @click="goMamageList(itme.type)">
          <van-icon style="display: block;font-size:1.6rem;padding-bottom:0.2rem;" :name="itme.icon"
            :color="itme.color" />
          <span style="font-size:0.8rem">{{ itme.title }}</span>
        </van-col>
        <!--        <van-col v-show="isShowReceivingOrder()" span="8" style="padding:1rem 0;" @click="isSetReceiving">-->
        <!--          <van-icon style="display: block;font-size:1.6rem;padding-bottom:0.2rem;" name="service-o" color="#00e500"/>-->
        <!--          <span style="font-size:0.8rem" v-show="receivingOrder==0">暂不接单</span>-->
        <!--          <span style="font-size:0.8rem" v-show="receivingOrder==1">正常接单</span>-->
        <!--        </van-col>-->
        <van-col span="8" style="padding:1rem 0;" @click="loginOut">
          <van-icon style="display: block;font-size:1.6rem;padding-bottom:0.2rem;" name="friends-o"
            color="rgb(145, 141, 148)" />
          <span style="font-size:0.8rem">退出登录</span>
        </van-col>
        <van-col span="8" style="padding:1.2rem;" @click="showPwd = true">
          <van-icon style="display: block;font-size:1.6rem;padding-bottom:0.2rem;" name="edit" color="#f00" />
          <span style="font-size:0.8rem">修改密码</span>
        </van-col>
      </van-row>
    </van-cell-group>
    <van-popup v-model="showPwd" position="bottom">
      <van-form @submit="onSubmit">
        <van-field v-model="pwd.oldPassword" type="password" clearable label="旧密码" required placeholder="请填写旧密码"
          :rules="[{ required: true }]" />
        <van-field v-model="pwd.newPassword" type="password" clearable label="新密码" required placeholder="请填写新密码"
          :rules="[{ required: true }]" />
        <van-field v-model="newPassword2" type="password" clearable label="确认密码" required placeholder="请填写确认密码"
          :rules="[{ required: true }]" />
        <div style="margin:1rem;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Dialog } from 'vant';
import { authorization } from '../authorization/authorization'
export default {
  data() {
    return {
      pwd: {
        oldPassword: "",
        newPassword: "",
      },
      newPassword2: "",
      showPwd: false,
      userName: localStorage.getItem("userName"),
      receivingOrder: localStorage.getItem("receivingOrder"),
      roleName: this.getRoleName(),
       orderList: [
        { type: -1, title: "我的订单", color: "#7af", icon: "orders-o", num: 0 },
        { type: 6, title: "待结算订单", color: "#fa0", icon: "balance-list-o", num: 0 },
        { type: 7, title: "已结算订单", color: "#f00", icon: "bill-o", num: 0 },

        // { type: 1, title: "待首联订单", color: "#7af", icon: "phone-circle-o", num: 0 },
        // { type: 9, title: "电表申请中", color: "#fa0", icon: "newspaper-o", num: 0 },
        { type: 2, title: "待安装订单", color: "#f00", icon: "todo-list-o", num: 0 },

        { type: 3, title: "待审核订单", color: "#7af", icon: "completed", num: 0 },
        { type: 4, title: "被驳回订单", color: "#fa0", icon: "failure", num: 0 },
        // { type: 5, title: "暂不安装订单", color: "#f00", icon: "description", num: 0 },
        { type: 16, title: "今日待安装", color: "#fa2", icon: "todo-list-o", num: 0 },
        // { type: 10, title: "等待客户通知", color: "#7af", icon: "description", num: 0 },
        { type: 11, title: "取消待审核", color: "#fa0", icon: "description", num: 0 },
        { type: 12, title: "已取消", color: "#f00", icon: "description", num: 0 },

        { type: -2, title: "维修单", color: "#7af", icon: "description", num: 0 },
        { type: -3, title: "自提单", color: "#fa0", icon: "description", num: 0 },
        // { type: 13, title: "待勘测订单", color: "#f00", icon: "todo-list-o", num: 0 },
      ],
      mamageList: [
        { type: 1, title: "订单管理", color: "#7af", icon: "records", isHidden: !authorization('/home-admin/order/order-manage') },
        { type: 2, title: "充电桩管理", color: "#fa0", icon: "desktop-o", isHidden: !authorization('/home-admin/device/device-manage') },
      ]
    };
  },
  created() {
    if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined) {
      this.$router.push('/Login')
    }
    this.getOrderCount()
  },
  methods: {
    authorization,
    getOrderCount() {
      this.axios({
        method: "get",
        url: "/v1/h5/order-info/select-order-count"
      }).then((res) => {
        for (let i = 0; i < this.orderList.length; i++) {
          if (this.orderList[i].type === -1) {
            this.orderList[i].num = res.data.data.totalCount
          } else if (this.orderList[i].type === -2) {
            this.orderList[i].num = res.data.data.repairCount
          } else if (this.orderList[i].type === -3) {
            this.orderList[i].num = res.data.data.selfPickUpCount
          } else if (this.orderList[i].type === 1) {
            this.orderList[i].num = res.data.data.beFirstCount
          } else if (this.orderList[i].type === 2) {
            this.orderList[i].num = res.data.data.beInstallCount
          } else if (this.orderList[i].type === 3) {
            this.orderList[i].num = res.data.data.installCount
          } else if (this.orderList[i].type === 4) {
            this.orderList[i].num = res.data.data.rejectedCount
          } else if (this.orderList[i].type === 5) {
            this.orderList[i].num = res.data.data.noInstallCount
          } else if (this.orderList[i].type === 6) {
            this.orderList[i].num = res.data.data.beSettledCount
          } else if (this.orderList[i].type === 7) {
            this.orderList[i].num = res.data.data.settledCount
          } else if (this.orderList[i].type === 9) {
            this.orderList[i].num = res.data.data.meterCount
          } else if (this.orderList[i].type === 10) {
            this.orderList[i].num = res.data.data.waitConfirmation
          } else if (this.orderList[i].type === 11) {
            this.orderList[i].num = res.data.data.cancelExamineCount
          } else if (this.orderList[i].type === 12) {
            this.orderList[i].num = res.data.data.cancelCount
          } else if (this.orderList[i].type === 16) {
            this.orderList[i].num = res.data.data.beInstalledTodayCount
          }
          // else if (this.orderList[i].type === 13) {
          //   this.orderList[i].num = res.data.data.surveyCount
          // }
        }
      })
    },
    onSubmit() {
      if (this.pwd.newPassword !== this.newPassword2) {
        Toast.fail('两次密码不一致');
      } else {
        this.axios({
          method: "post",
          url: "/v1/h5/user-info/update-password",
          data: this.pwd,
        }).then((res) => {
          if (res.data.code === 200) {
            this.showPwd = false;
            Toast.success('修改成功');
          } else {
            Toast.fail(res.data.message);
          }
        });
      }
    },
    getRoleName() {
      let roleList = JSON.parse(localStorage.getItem("roleList"));
      let resultName = ""
      for (let i = 0; i < roleList.length; i++) {
        if (i > 0) {
          resultName = resultName + ","
        }
        resultName = resultName + roleList[i].roleName
      }
      
      return resultName;
    },
    isShowReceivingOrder() {
      let roleList = JSON.parse(localStorage.getItem("roleList"));
      for (let i = 0; i < roleList.length; i++) {
        if (roleList[i].id === 3) {
          return true;
        }
      }
      return false;
    },
    goback() {//返回上一页
      this.$router.push('/Login');
    },
    goOrderList(type) {//跳转到订单页面
      this.$router.push({ path: '/OrderList', query: { type: type } });
    },
    goMamageList(type) {
      if (type == 1) {//跳转到订单管理页面
        this.$router.push('/OrderManage');
      } else if (type == 2) {//跳转到设备管理页面
        this.$router.push('/MyDevice');
      }
    },
    isSetReceiving() {
      let message = this.receivingOrder == 0 ? "是否暂停接单" : "是否开始接单"
      Dialog.confirm({
        title: '接单设置',
        message: message,
      })
        .then(() => {
          // on confirm
          this.setOrderTaking()
        })
    },
    setOrderTaking() {
      let receivingOrder = this.receivingOrder == 0 ? 1 : 0
      this.axios({
        method: "post",
        url: "/v1/h5/user-info/set-order-taking",
        params: {
          receivingOrder: receivingOrder
        },
      }).then((res) => {
        if (res.data.code === 200) {
          Toast.success("设置成功");
          localStorage.setItem("receivingOrder", receivingOrder)
          this.receivingOrder = receivingOrder
        } else {
          Toast.fail(res.data.message);
        }
      })
    },
    loginOut() {
      Dialog.confirm({
        title: '退出登录',
        message: "是否确认退出",
      })
        .then(() => {
          this.axios({
            method: "post",
            url: "/v1/h5/user-info/login-out"
          }).then((res) => {
            if (res.data.code === 200) {
              Toast.success("退出成功");
              localStorage.removeItem("token")
              this.$router.push('/Login')
            } else {
              Toast.fail(res.data.message);
            }
          })
        })
    }
  },
};
</script>

<style></style>
